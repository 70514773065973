// Generated by Framer (2bdc57c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["uYlorpJN8", "gdBulsxLX"];

const variantClassNames = {gdBulsxLX: "framer-v-1pbetk", uYlorpJN8: "framer-v-1yxjxle"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Variant 1": "uYlorpJN8", "Variant 2": "gdBulsxLX"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "uYlorpJN8", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "uYlorpJN8", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-E0JZl", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1yxjxle", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"uYlorpJN8"} ref={ref} style={{backgroundColor: "var(--token-4477660f-5674-4d28-9275-3f9252ba5075, rgb(255, 255, 255))", ...style}} transition={transition} {...addPropertyOverrides({gdBulsxLX: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-E0JZl [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-E0JZl .framer-17nialw { display: block; }", ".framer-E0JZl .framer-1yxjxle { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 40px; height: min-content; justify-content: flex-start; max-width: 1440px; min-height: 808px; padding: 60px 60px 60px 60px; position: relative; width: 1440px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-E0JZl .framer-1yxjxle { gap: 0px; } .framer-E0JZl .framer-1yxjxle > * { margin: 0px; margin-bottom: calc(40px / 2); margin-top: calc(40px / 2); } .framer-E0JZl .framer-1yxjxle > :first-child { margin-top: 0px; } .framer-E0JZl .framer-1yxjxle > :last-child { margin-bottom: 0px; } }", ".framer-E0JZl.framer-v-1pbetk .framer-1yxjxle { flex-direction: row; min-height: 720px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-E0JZl.framer-v-1pbetk .framer-1yxjxle { gap: 0px; } .framer-E0JZl.framer-v-1pbetk .framer-1yxjxle > * { margin: 0px; margin-left: calc(40px / 2); margin-right: calc(40px / 2); } .framer-E0JZl.framer-v-1pbetk .framer-1yxjxle > :first-child { margin-left: 0px; } .framer-E0JZl.framer-v-1pbetk .framer-1yxjxle > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 808
 * @framerIntrinsicWidth 1440
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"],"constraints":[null,"1440px",null,null]},"gdBulsxLX":{"layout":["fixed","auto"],"constraints":[null,"1440px",null,null]}}}
 */
const FramerTLqF_PXTh: React.ComponentType<Props> = withCSS(Component, css, "framer-E0JZl") as typeof Component;
export default FramerTLqF_PXTh;

FramerTLqF_PXTh.displayName = "Client Quote Feature";

FramerTLqF_PXTh.defaultProps = {height: 808, width: 1440};

addPropertyControls(FramerTLqF_PXTh, {variant: {options: ["uYlorpJN8", "gdBulsxLX"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerTLqF_PXTh, [])